import React, { useEffect } from "react";
import "../style.css";

const IntegrationForm = () => {
  useEffect(() => {
    const loadHubspotForm = () => {
      if (window.hbspt) {
      

        // Create the HubSpot form
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "145450957",
          formId: "4b47dce5-d9f6-4395-994f-f22acb75022c",
          target: "#hubspotForm",
          onFormReady: () => { // Callback when the form is ready
          },
          onFormError: (error) => { // Callback for any error during form creation
          },
        });
      } else {
        console.error("HubSpot script not loaded.");
      }
    };

    const addHubspotScript = () => {
      const script = document.createElement("script");
      script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
      script.charset = "utf-8";
      script.async = true;
      script.onload = () => {
        loadHubspotForm();
      };
      script.onerror = () => {
        console.error("Failed to load HubSpot script.");
      };
      document.body.appendChild(script);
    };

    if (!document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]')) {
      addHubspotScript();
    } else {
      loadHubspotForm();
    }
  }, []);

  return (
    <div className="hubspot-form-container">
      <h2 className="hubspot-form-title">Start Integration</h2>
      <div id="hubspotForm" className="hubspot-form"></div>
    </div>
  );
};

export default IntegrationForm;

import React, { useState, useEffect } from "react";
import Sidebar from "../components/SideBar/SideBar";
import { Route, Routes, useLocation } from "react-router-dom";
import "../style.css";
import AllRequests from "./allRequest";
import TrashPage from "./TrashPage";
import FunctionalEditor from "../components/froalaEdito/CustomFroalaEditor";
import { useAuth } from "../components/ProtectedRoute/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiService } from "../services/apiService";
import DeletePopUp from "../components/PopUps/DeletePopUp";
import IntegrationForm from "./IntegrationPage";

const HomePage = () => {
  const { setIsLoggedIn } = useAuth();
  const [isSessionExpiredPopVisible, setIsSessionExpiredPopVisible] =
    useState(false);
  const [isLogoutPopVisible, setIsLogoutPopVisible] = useState(false);
  const [activeItem, setActiveItem] = useState("All Requests");
  const [isTeamspaceOpen, setIsTeamspaceOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token || apiService.isTokenExpired()) {
      setIsLoggedIn(false);
      setIsSessionExpiredPopVisible(true);
      apiService.logout();
    }
  }, [setIsLoggedIn]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("open-byte")) {
      setActiveItem("All Requests");
    } else if (path.includes("resolved-byte")) {
      setActiveItem("All Requests");
    } else if (path.includes("trash")) {
      setActiveItem("Trash");
    } else if (path.includes("integration")) {
      setActiveItem("Integration");
    } else if (path.includes("document-edit") || path.includes("document")) {
      setIsTeamspaceOpen(true);
      const id = path.split("/").pop();
      setActiveItem(id);
    } else if (path.includes("feedback")) {
      setActiveItem("Feedback");
    } else {
      setActiveItem("");
    }
  }, [location, setIsLoggedIn, navigate]);

  const handleOpenLogoutPopUp = () => {
    setIsLogoutPopVisible(true);
  };
  return (
    <div className="home">
      <Sidebar
        activeItem={activeItem}
        isTeamspaceOpen={isTeamspaceOpen}
        setIsTeamspaceOpen={setIsTeamspaceOpen}
        handleOpenLogoutPopUp={handleOpenLogoutPopUp}
      />
      <div className="content">
        <Routes>
          <Route
            path="all-requests/open-byte"
            element={<AllRequests tab="open" />}
          />
          <Route
            path="all-requests/resolved-byte"
            element={<AllRequests tab="resolved" />}
          />
          <Route path="trash" element={<TrashPage />} />
          <Route path="integration" element={<IntegrationForm />} />
          <Route
            path=":byteId/document-edit/:id/"
            element={<FunctionalEditor activeItem={activeItem} />}
          />
          <Route
            path="document/:id"
            element={<FunctionalEditor activeItem={activeItem} />}
          />
        </Routes>
        <DeletePopUp
          isVisible={isSessionExpiredPopVisible || isLogoutPopVisible}
          title={isSessionExpiredPopVisible ? "Session has expired" : "Log out"}
          buttonText={isSessionExpiredPopVisible ? "Login again" : "Log out"}
          subtitle={
            isSessionExpiredPopVisible
              ? "Sorry, but you need to login again to continue"
              : "Are you sure you want to log out?"
          }
          desc={isSessionExpiredPopVisible ? "" : "You will be logged out."}
          onClick={() => {
            if (isSessionExpiredPopVisible) {
              navigate("/");
            } else {
              apiService.logout();
              setIsLoggedIn(false);
              navigate("/");
            }
          }}
          onClose={() => {
            setIsSessionExpiredPopVisible(false);
            setIsLogoutPopVisible(false);
          }}
        />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useRef, useState } from "react";
import icons from "../../assets/icons";
import "./tab-body-style.css";
import CustomTooltip from "../PopUps/CustomToolTip";
import InfoTooltip from "../PopUps/InfoToolTip";

const OpenRequestComponent = ({
  title,
  employee_name,
  date,
  aiEdits,
  inProgress,
  onClick,
  onClickDelete,
}) => {
  const tooltipId = "open-request-tooltip";
  const progressTooltipId = "progress-tooltip";

  const byteDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const time = new Date(date).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const titleRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current) {
        const textWidth = titleRef.current.scrollWidth;
        const containerWidth = titleRef.current.clientWidth;
        setShowTooltip(textWidth > containerWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [title]);
  const containerClass = `open-request-container ${
    inProgress ? "inprogress" : ""
  }`;

  return (
    <div
      className={containerClass}
      onClick={() => {
        if (!inProgress) {
          onClick();
        }
      }}
    >
      <div className="main-content">
        <p
          ref={titleRef}
          data-tooltip-id={tooltipId}
          data-tooltip-content={showTooltip ? title : ""}
          className={showTooltip ? "has-tooltip" : ""}
        >
          {title}
        </p>
        <div className="main-content-row">
          <span>{employee_name}</span>
          <p>
            {byteDate} {time}
          </p>
        </div>
      </div>
      <div className="ai-edit">
        <span>{aiEdits}</span>
        <p>AI Edits</p>
        {inProgress ? (
          <p
            data-tooltip-id={progressTooltipId}
            data-tooltip-content="Sit back and relax — AI is scanning the teamspace and keeping your documents up to date!"
            className="in-progress"
          >
            Analyzing Byte
          </p>
        ) : aiEdits === 0 ? (
          <p className="in-progress">Irrelevant Byte</p>
        ) : null}
      </div>
      <img
        className="delete-icon"
        src={icons.trashIcon}
        alt="icon"
        onClick={(e) => {
          e.stopPropagation();
          onClickDelete();
        }}
      />
      {showTooltip && <CustomTooltip id={tooltipId} />}
      <InfoTooltip id={progressTooltipId} />
    </div>
  );
};

export default OpenRequestComponent;

import React, { useState, useRef, useEffect, useCallback } from "react";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/froala_style.min.css";
import ChangeRequest from "./changeRequest.js";
import "./editor-style.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import RecommendationSkeletonLoader from "../loading-screen/RecommendationSkeleton.js";
import EditorSkeleton from "../loading-screen/EditorSkeleton.js";
import ResolveChangeRequestPopUp from "../PopUps/ResolveChangeRequestPopUp.js";
import { toast } from "react-toastify";
import NotificationBar from "./NotificationBar.js";
import SuggestionList from "./SuggestionListComponent.js";
import { diff_match_patch } from "diff-match-patch";

//Old debounce
// const debounce = (func, delay) => {
//   let timeoutId;
//   return (...args) => {
//     if (timeoutId) {
//       clearTimeout(timeoutId);
//     }
//     timeoutId = setTimeout(() => {
//       func.apply(null, args);
//     }, delay);
//   };
// };
const debounceWithCancel = (func, delay) => {
  let timeoutId;
  let isCancelled = false;

  const debouncedFunction = (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    isCancelled = false;
    timeoutId = setTimeout(() => {
      if (!isCancelled) {
        func.apply(null, args);
      }
    }, delay);
  };

  debouncedFunction.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    isCancelled = true;
  };

  return debouncedFunction;
};

const FunctionalEditor = () => {
  const debounceDelay = 2000; // 3 seconds
  // const [requestData, setRequestData] = useState(null);
  const requestDataRef=useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState("Loading");
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(true);
  const [showChangeRequest, setShowChangeRequest] = useState(false);
  const [recommendationData, setRecommendationData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);
  const [showResolveWarning, setShowResolveWarning] = useState(false);
  const [showAutoRejectWarning, setshowAutoRejectWarning] = useState(false);
  const changedModelRef = useRef(model);
  const showChangeRequestRef = useRef(showChangeRequest);
  const [currentRecommendationIndex, setCurrentRecommendationIndex] =
    useState(-1);
  const [isDirty, setIsDirty] = useState(false);
  const acceptedArray = useRef([]);
  const rejectedArray = useRef([]);
  const activeFileName = useRef(fileName);
  const activeDocIdRef = useRef("");
  const [activeRecommendationData, setActiveRecommendationData] =
    useState(null);
  const activeRecommendationDataRef = useRef(activeRecommendationData);
  const [sortedRecommendationData, setSortedRecommendationData] =
    useState(null);
  const dBModelRef = useRef("");
  const recommendationDataRef = useRef(recommendationData);
  const [editorWidth, setEditorWidth] = useState(850);
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { id, byteId } = useParams();

  useEffect(() => {
    if (location.pathname.includes("document-edit")) {
      setShowChangeRequest(true);
    } else if (location.pathname.includes("document")) {
      setShowChangeRequest(false);
      if (activeDocIdRef.current !== String(id)) {
        setIsLoading(true);
      }
    }
  }, [location.pathname]);
  useEffect(() => {
    showChangeRequestRef.current = showChangeRequest;
  }, [showChangeRequest]);

  useEffect(() => {
    if (!showChangeRequestRef.current) {
      fetchData();
    }
    activeDocIdRef.current = id;
  }, [location.pathname, showChangeRequest]);

  //FETCH DOCUMENT DATA
  const fetchData = async () => {
    try {
      if (location.pathname.includes("document-edit")) {
        await handleByteBasedRecommendation();
      } else if (location.pathname.includes("document")) {
        await handleDocumentBasedRecommendation();
      }
    } catch (error) {
      setIsLoading(true);
    }
  };

  const codoxInstance = useRef(null);

  const handleEditorInit = () => {
    try {
      if (editorRef.current) {
        const editorInstance = editorRef.current.getEditor();
        const config = {
          app: "froala",
          docId: activeDocIdRef.current,
          username: localStorage.getItem("email"),
          editor: editorInstance,
          apiKey: "d5bb1f48-356b-4032-8d0c-ba1a79396f79",
        };

        codoxInstance.current = new window.Codox();
        codoxInstance.current.init(config);
      }
    } catch (e) {
      toast.error("Real-Time Update Error");
    }
  };

  // useEffect(() => {
  //   // Ensure Froala is fully initialized before Codox
  //   if (editorRef.current) {
  //     const editorInstance = editorRef.current.getEditor();
  //     editorInstance.events.on("initialized", handleEditorInit);
  //   }

  //   return () => {
  //     // Cleanup on unmount
  //     if (codoxInstance.current) {
  //       codoxInstance.current.destroy();
  //     }
  //   };
  // }, []);

  // Function to handle Byte-based recommendations
  const handleByteBasedRecommendation = async () => {
    try {
      const response = await apiService.getRecommendationForByte(byteId);
      if (response) {
        const url = determineDocumentURL(response);
        const htmlContent = await fetchHTMLContent(url);
        processByteBasedDocuments(response);
        setFileName(url.substring(url.lastIndexOf("/") + 1));
        setModel(htmlContent); //TODO: OPTIMISE THIS CODE
        requestDataRef.current=response.data;  
        //  setRequestData(response.data);
        dBModelRef.current = htmlContent;
        setIsLoading(false);
      }
    } catch (e) {
      toast.error("Invalid Document! Please try again");
      navigate("/home/all-requests/open-byte");
    }
  };

  // Function to determine document URL
  const determineDocumentURL = (response) => {
    if (!activeRecommendationDataRef.current) {
      navigate(
        `/home/${byteId}/document-edit/${response.data.documents[0].doc_id}`,
        { replace: true }
      );
      return response.data.documents[0].doc_content;
    } else {
      navigate(
        `/home/${byteId}/document-edit/${activeRecommendationDataRef.current.doc_id}`,
        { replace: true }
      );
      return activeRecommendationDataRef.current.doc_content;
    }
  };

  // Function to process byte-based documents
  const processByteBasedDocuments = (response) => {
    if (response.data.documents && response.data.documents.length > 0) {
      let allRecommendations = [];
      for (let i = 0; i < response.data.documents.length; i++) {
        const document = response.data.documents[i];
        const { doc_id, doc_content, recommendations } = document;

        const mappedRecommendations = recommendations.map((rec) => ({
          ...rec,
          doc_content,
          byte_id: response.data.request_id,
          doc_id,
          x: 0,
          y: -9999,
        }));
        allRecommendations.push(...mappedRecommendations);
      }
      setRecommendationData(JSON.parse(JSON.stringify(allRecommendations)));
    }
  };

  // Function to handle document-based recommendations
  const handleDocumentBasedRecommendation = async () => {
    const response = await apiService.getRecommendationSingleDoc(id);
    if (response) {
      const url = response.data.document.doc_content;
      const htmlContent = await fetchHTMLContent(url);
      processDocumentBytes(response);
      requestDataRef.current=response;
      // setRequestData(response);
      setFileName(url.substring(url.lastIndexOf("/") + 1));
      setModel(htmlContent);
      setActiveRecommendationData(null);
      dBModelRef.current = htmlContent;
      setIsLoading(false);
    }
  };

  // Function to process document bytes
  const processDocumentBytes = (response) => {
    const { document } = response.data;
    if (document.bytes && document.bytes.length > 0) {
      const doc_id = document.doc_id;
      const mappedRecommendations = document.bytes.flatMap((byte) =>
        byte.recommendations.map((rec) => ({
          ...rec,
          doc_content: document.doc_content,
          byte_id: byte.byteId,
          doc_id,
          x: 0,
          y: -9999,
        }))
      );
      setRecommendationData(mappedRecommendations);
    } else {
      setRecommendationData([]);
    }
  };

  // Helper function to fetch and parse HTML content
  const fetchHTMLContent = async (url) => {
    const cacheBuster = `?_=${new Date().getTime()}`;
    const fullUrl = url.includes("?")
      ? `${url}&${cacheBuster}`
      : `${url}${cacheBuster}`;

    const htmlResponse = await fetch(fullUrl, {
      mode: "cors",
      cache: "no-store",
    });
    if (!htmlResponse.ok) {
      return; //ADDED FOR GETTING DOC REMOVED THROW
    }
    const htmlText = await htmlResponse.text();
    return htmlText;
  };

  //Handle size of change Request Header
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setEditorWidth(entry.contentRect.width);
      }
    });
    if (editorRef.current) {
      resizeObserver.observe(editorRef.current);
    }
    return () => {
      if (editorRef.current) {
        resizeObserver.unobserve(editorRef.current);
      }
    };
  }, [editorRef, model]);

  //AUTO ADJUST SCROLLER
  useEffect(() => {
    if (!activeRecommendationData || !showChangeRequest) return;

    const container = containerRef.current;
    if (container) {
      const activeRecommendation = recommendationData.find(
        (rec) => rec.id === activeRecommendationData.id
      );

      const delay = 600;

      const scrollWithDelay = () => {
        if (activeRecommendation) {
          const scrollPosition = activeRecommendation.y - 200;
          container.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        } else {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }
      };

      setTimeout(scrollWithDelay, delay);
    }
  }, [
    activeRecommendationData,
    currentRecommendationIndex,
    // recommendationData,
    // showChangeRequest,
  ]);

  useEffect(() => {
    activeRecommendationDataRef.current = activeRecommendationData;
  }, [activeRecommendationData]);

  useEffect(() => {
    recommendationDataRef.current = recommendationData;
    setSortedRecommendationData(recommendationDataRef.current);
  }, [recommendationData]);

  useEffect(() => {
    activeFileName.current = fileName;
  }, [fileName]);

  const uploadDocument = async (newContent, docId) => {
    if (docId !== activeDocIdRef.current) {
      return;
    }
    const cleanedContent = removeMarkTags(newContent);
    if (cleanedContent !== dBModelRef.current) {
      const htmlBlob = new Blob([cleanedContent], { type: "text/html" });
      const htmlFile = new File([htmlBlob], activeFileName.current, {
        type: "text/html",
        lastModified: Date.now(),
      });
      await apiService.uploadDocument(htmlFile, docId);
      dBModelRef.current = cleanedContent;
      setIsDirty(false);
    }
  };

  const debouncedUpload = debounceWithCancel((newContent, docId) => {
    uploadDocument(newContent, docId);
  }, debounceDelay);

  const handleBeforeUnload = (event) => {
    if (isDirty) {
      uploadDocument(changedModelRef.current, activeDocIdRef.current);
      event.preventDefault();
      event.returnValue = "You have unsaved changes. Are you sure to leave?";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, location]);

  const handleUpdate = () => {
    if (isDirty) {
      uploadDocument(changedModelRef.current, activeDocIdRef.current);
    }
  };

  const removeMarkTags = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const marks = tempElement.querySelectorAll("mark");
    const brTags = tempElement.querySelectorAll(`br[data-location]`);
    const spanTags = tempElement.querySelectorAll(`span[data-location]`);

    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;
      if (isRemoved) {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      } else {
        mark.remove();
      }
    });

    brTags.forEach((br) => {
      br.remove();
    });
    spanTags.forEach((span) => {
      span.remove();
    });
    const froalaTag = tempElement.querySelector(
      'p a[title="Froala Editor"]'
    )?.parentNode;
    if (froalaTag) {
      froalaTag.remove();
    }

    return tempElement.innerHTML;
  };

  const handleContentChange = (newContent, docId) => {
    setIsDirty(true);
    debouncedUpload(newContent, docId);
  };

  //Recommendation Functionality
  useEffect(() => {
    if (requestDataRef.current) {
      changedModelRef.current = model;
    }
  }, [model]);

  useEffect(() => {
    if (
      activeRecommendationData &&
      !acceptedArray.current.includes(activeRecommendationData.id) &&
      !rejectedArray.current.includes(activeRecommendationData.id) &&isProcessingComplete
    ) {
     highlightText();
    }
  }, [
    activeRecommendationData,
    recommendationData,isProcessingComplete
    // currentRecommendationIndex,
  ]);

  useEffect(() => {
    if (recommendationData) {
    processAllRecommendations();
      setIsProcessingComplete(true);
    }
  }, [recommendationData, fileName]);

  const rejectHiddenRecommendations = async () => {
    for (let recommendation of recommendationData) {
      if (
        recommendation.y === "hide" &&
        !rejectedArray.current.includes(recommendation.id)
      ) {
        try {
          setshowAutoRejectWarning(true);
          const response = await apiService.acceptRejectByte(
            recommendation.byte_id,
            recommendation.doc_id,
            "h1",
            recommendation.section_main_heading1,
            recommendation.previous_string,
            "REJECTED",
            recommendation.id,
            "Rejected By FE"
          );
          if (response.success) {
            rejectedArray.current.push(recommendation.id);
          }
        } catch (error) {
        } finally {
          setTimeout(() => {
            setshowAutoRejectWarning(false);
          }, 300);
        }
      }
    }
  };
  useEffect(() => {
    if (recommendationData) {
      rejectHiddenRecommendations();
    }
  }, [recommendationData, activeRecommendationData, sortedRecommendationData]);

  useEffect(() => {
    if (recommendationData && activeRecommendationData) {
      const newIndex = sortedRecommendationData.findIndex(
        (recommendation) => recommendation.id === activeRecommendationData.id
      );

      if (newIndex !== -1) {
        setCurrentRecommendationIndex(newIndex);
      }
    }
  }, [activeRecommendationData, sortedRecommendationData]);

  //ADD RECOMMENDATION
  const reverseHighlightLogic = (doc) => {
    const marks = doc.querySelectorAll("mark");

    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;

      if (isRemoved) {
        // while (mark.firstChild) {
        //   parent.insertBefore(mark.firstChild, mark);
        // }
        mark.remove();
      } else {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      }
    });

    const elements = "p, h1, h2, h3, span, div, li, ul, ol";
    removeElementsWithOnlyBr(doc, elements);
  };
  const addText = (recommendationId) => {
    if (!requestDataRef.current) return false;

    if (activeRecommendationData) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(changedModelRef.current, "text/html");

      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );

      if (recommendationElement) {
        recommendationElement.remove();
      }

      reverseHighlightLogic(doc);
      removeElementsWithOnlyBr(doc);
      const newModel = doc.documentElement.outerHTML;
      setModel(newModel);
      uploadDocument(newModel, activeDocIdRef.current);
      return true;
    }

    return false;
  };

  //REJECT RECOMMENDATION
  const rejectRecommendation = (recommendationId) => {
    if (!requestDataRef.current) return false;
    if (activeRecommendationData) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(changedModelRef.current, "text/html");
      const recommendationElement = doc.querySelector(
        `span[data-location="recommendation_${recommendationId}"]`
      );

      if (recommendationElement) {
        const parent = recommendationElement.parentNode;
        if (parent) {
          recommendationElement.remove();
          clearPreviousHighlights(doc);
          const newModel = doc.documentElement.outerHTML;
          changedModelRef.current = newModel;
          setModel(newModel);
          return true;
        }
      }
    }
    return false;
  };

  const removeEmptyParagraphs = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const paragraphs = tempElement.querySelectorAll("p");

    paragraphs.forEach((paragraph) => {
      if (
        paragraph.innerHTML.trim() === "" ||
        (paragraph.children.length === 1 &&
          paragraph.firstElementChild.tagName === "BR")
      ) {
        paragraph.remove();
      }
    });
    return tempElement.innerHTML;
  };

  //Froala Model Callback
  const handleModelChange = useCallback((newModel) => {
    setModel(newModel);
  }, []);

  //Navigation for document >> document-edit
  const handleNavigation = (recommendation) => {
    setActiveRecommendationData(recommendation);
    if (showChangeRequest) {
    } else {
      navigate(`/home/${recommendation.byte_id}/document-edit/loading`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const spans = document.querySelectorAll("span[data-location]");
    const clickHandler = (event) => {
      event.stopPropagation();
      const recId = parseInt(
        event.target.getAttribute("data-location").split("_")[1]
      );
      const recommendation = recommendationDataRef.current.find(
        (rec) => rec.id === recId
      );

      if (recommendation) {
        handleNavigation(recommendation);
      }
    };
    spans.forEach((span) => {
      const recId = parseInt(span.getAttribute("data-location").split("_")[1]);
      const recommendationIndex = recommendationDataRef.current.findIndex(
        (rec) => rec.id === recId
      );
      span.addEventListener("click", clickHandler);
      if (
        recommendationIndex !== -1 &&
        span.innerHTML !== (recommendationIndex + 1).toString()
      ) {
        span.innerHTML = (recommendationIndex + 1).toString();
      } else {
      }
      return () => {
        spans.forEach((span) => {
          span.removeEventListener("click", clickHandler);
        });
      };
    });
  }, [recommendationDataRef.current, model]);

  // Sorting setRecommendationData increasing y (sortedRecommendations);
  const sortRecommendationsByDocIdAndY = (recommendations) => {
    if (!recommendations || recommendations.length <= 0) {
      return;
    }
    const filteredRecommendations = recommendations.filter(
      (recommendation) => recommendation.y !== "hide"
    );

    const groupedByDocId = {};

    // Group recommendations by doc_id
    filteredRecommendations.forEach((recommendation) => {
      const { doc_id } = recommendation;
      if (!groupedByDocId[doc_id]) {
        groupedByDocId[doc_id] = [];
      }
      groupedByDocId[doc_id].push(recommendation);
    });

    // Sort each group by y
    Object.keys(groupedByDocId).forEach((docId) => {
      groupedByDocId[docId].sort((a, b) => a.y - b.y);
    });

    const sortedRecommendations = [];

    filteredRecommendations.forEach((recommendation) => {
      if (
        !sortedRecommendations.some(
          (rec) => rec.doc_id === recommendation.doc_id
        )
      ) {
        sortedRecommendations.push(...groupedByDocId[recommendation.doc_id]);
      }
    });

    return sortedRecommendations;
  };

  //CHECK FOR RANGE
  useEffect(() => {
    if (!recommendationData || recommendationData.length <= 0 || !isProcessingComplete) {
      setIsSuggestionLoading(false);
      return;
    }
    let smallestYRec = null;
    const updatedRecommendations = [...recommendationData];
    const spans = document.querySelectorAll("span[data-location]");

    spans.forEach((span) => {
      const recId = parseInt(span.getAttribute("data-location").split("_")[1]);
      const recommendation = updatedRecommendations.find(
        (rec) => rec.id === recId
      );
      if (recommendation) {
        const rect = span.getBoundingClientRect();
        const container = containerRef?.current;
        const scrollOffset = container
          ? container.scrollTop
          : window.scrollY || document.documentElement.scrollTop;

        let calculatedY = rect.top + scrollOffset - 100;
        if (calculatedY < 0) {
          calculatedY = 10;
        }
        if (
          acceptedArray.current.includes(recId) ||
          rejectedArray.current.includes(recId)
        ) {
          return;
        }
        const isWithinRange = Math.abs(recommendation.y - calculatedY) <= 250;
        if (!isWithinRange || recommendation.y < 0) {
          recommendation.y = calculatedY;
          while (
            updatedRecommendations.some(
              (rec) =>
                rec.id !== recId && Math.abs(rec.y - recommendation.y) <= 90
            )
          ) {
            recommendation.y += 110;
          }
          if (
            !smallestYRec ||
            (recommendation.y <= smallestYRec.y && recommendation.y > 0)
          ) {
            smallestYRec = recommendation;
          }
        }
      }
    });
 if (smallestYRec && !activeRecommendationData && showChangeRequest) {
 setActiveRecommendationData(smallestYRec);
    } else {
      const combinedRecommendations = [
        ...acceptedArray.current,
        ...rejectedArray.current,
      ];
      const smallestY = combinedRecommendations.reduce((smallest, rec) => {
        if (
          rec.docId === activeDocIdRef.current &&
          rec.y !== -9999 &&
          rec.y !== "hide"
        ) {
          return !smallest || rec.y < smallest.y ? rec : smallest;
        }
       return smallest;
      }, null);
      if (smallestY && !activeRecommendationData && showChangeRequest) {
        setActiveRecommendationData(smallestY);
      }else{
        const docFilteredRecommendations = recommendationData.filter(
          (rec) => String(rec.doc_id) === String(activeDocIdRef.current)
        );
        const smallestYD = docFilteredRecommendations.reduce((smallest, rec) => {
          if (!smallest || (rec.y < smallest.y && rec.y !== -9999 && rec.y !== "hide")) {
            return rec;
          }
          
          return smallest;
        }, null);
        if (smallestYD && !activeRecommendationData && showChangeRequest) {
           setActiveRecommendationData(smallestYD);
        }
      }
    }
    const sortedData = sortRecommendationsByDocIdAndY(updatedRecommendations);
    setSortedRecommendationData(sortedData);
    setIsSuggestionLoading(false);
  }, [model]);

  //PLACE ANNOTATION
  const processAllRecommendations = () => {
    if (!recommendationData || recommendationData.length === 0) {
      return;
    }

    let parser = new DOMParser();
    let doc = parser.parseFromString(changedModelRef.current, "text/html");
    recommendationDataRef.current.forEach((rec, index) => {
      if (
        !rec ||
        String(rec.doc_id) !== String(activeDocIdRef.current) ||
        acceptedArray.current.includes(rec.id) ||
        rejectedArray.current.includes(rec.id)
      ) {
        return;
      }
      addRecommendationAnnotation(doc, rec, index + 1);
    });
    const updatedModel = removeEmptyParagraphs(doc.documentElement.outerHTML);
 setModel(updatedModel);
  };

  //ADD ANNOTATION
  const addRecommendationAnnotation = (doc, rec, index) => {
    if (!rec) return;

    const stripTags = (htmlString) => {
      const doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };

    const normalizeTextContent = (node) =>
      node.textContent
        .replace(/<br\s*\/?>/gi, "")
        .replace(/[-"“”’‘']/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/\n/g, "")
        .replace(/\s+/g, "");
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = rec.change_request_text;
    let matchFound = false;
    let lastRecommendationElement = null;

    for (const child of tempContainer.childNodes) {
      if (!child.textContent?.trim()) {
        continue;
      }

      const searchText = stripTags(child.textContent || "");
      const normalizedRecommendation = searchText
        .replace(/\n/g, "")
        .replace(/\s+/g, "")
        .replace(/[-"“”’‘']/g, "");
      const flatBodyText = normalizeTextContent(doc.body);
      const matchStartIndex = flatBodyText.indexOf(normalizedRecommendation);
      if (matchStartIndex !== -1) {
        matchFound = true;
        let matchEndIndex = matchStartIndex + normalizedRecommendation.length;
        let currentOffset = 0;
        const addTag = (node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            const originalText = node.textContent;
            const normalizedText = originalText
              .replace(/\n/g, "")
              .replace(/\s+/g, "")
              .replace(/[-"“”’‘']/g, "");
            const textLength = normalizedText.length;

            if (
              currentOffset < matchEndIndex &&
              currentOffset + textLength > matchStartIndex
            ) {
              const recommendationElement = document.createElement("span");
              Object.assign(recommendationElement.style, {
                visibility: "hidden",
                position: "absolute",
                width: "1px",
                height: "1px",
                margin: "0px",
                borderRadius: "50%",
                backgroundColor: "#006666",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1px",
                zIndex: "40",
                userSelect: "none",
              });
              recommendationElement.setAttribute(
                "data-location",
                `recommendation_${rec.id}`
              );
              recommendationElement.setAttribute("data-index", index + 1);
              recommendationElement.setAttribute("contenteditable", "false");
              recommendationElement.innerHTML = index;

              let parent = node.parentNode;
              while (parent && parent.tagName !== child.tagName) {
                parent = parent.parentNode;
              }
              if (parent) {
                parent.parentNode.insertBefore(recommendationElement, parent);
                if (
                  lastRecommendationElement &&
                  lastRecommendationElement.parentNode
                ) {
                  lastRecommendationElement.parentNode.removeChild(
                    lastRecommendationElement
                  );
                }
                lastRecommendationElement = recommendationElement;
              }
            }
            currentOffset += textLength;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            Array.from(node.childNodes).forEach((childNode) => {
              addTag(childNode);
            });
          }
        };

        addTag(doc.body);
      } else {
        if (
          lastRecommendationElement &&
          lastRecommendationElement.nextSibling &&
          ["H1", "H2", "H3", "H4"].includes(
            lastRecommendationElement.nextSibling.nodeName
          )
        ) {
          continue;
        } else {
          break;
        }
      }
    }

    if (!matchFound) {
    }
  };

  //HIGHLIGHT TEXT TO BE CHANGED
  const highlightText = () => {
    if (!requestDataRef.current || !activeRecommendationData) {
      return;
    }
    let parser = new DOMParser();
    let doc;
    try {
      doc = parser.parseFromString(model, "text/html");
    } catch (error) {
      return;
    }
    const { previous_string, change_request_text } = activeRecommendationData;
    clearPreviousHighlights(doc);
    const differences = compareHtmlStrings(
      previous_string,
      change_request_text
    );
    differences.forEach((difference, index) => {
      const prevHtml = difference.prev;
      const newHtml = difference.new;
      let searchHtml = "";
      let isPrev = false;
      let isNext = false;
      if (!prevHtml) {
        const previousDiff = differences[index - 1];
        const nextDiff = differences[index + 1];
        const isPreviousHeading =
          previousDiff && /<(h[1-4])>/i.test(previousDiff.prev);
        if (isPreviousHeading && nextDiff && nextDiff.prev) {
          searchHtml = nextDiff.prev;
          isNext = true;
        } else if (previousDiff && previousDiff.prev) {
          searchHtml = previousDiff.prev;
          isPrev = true;
        } else if (nextDiff && nextDiff.prev) {
          searchHtml = nextDiff.prev;
          isNext = true;
        }
      } else {
        searchHtml = prevHtml;
      }
      diffChecker(prevHtml, newHtml, doc, searchHtml, isPrev, isNext);
    });
    setModel(doc.documentElement.outerHTML);
  };

  //NEW ONE
  function compareHtmlStrings(previousString, changeRequestText) {
    const parseHTML = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      return [...doc.body.childNodes];
    };

    const normalizeText = (text) =>
      text.trim().replace(/\s+/g, " ").toLowerCase();

    const calculateSimilarity = (text1, text2) => {
      const words1 = normalizeText(text1).split(" ");
      const words2 = normalizeText(text2).split(" ");
      const commonWords = words1.filter((word) => words2.includes(word));
      const similarity =
        (2 * commonWords.length) / (words1.length + words2.length);
      return similarity;
    };

    const nodesAreSimilar = (node1, node2) => {
      if (!node1 || !node2) return false;
      const text1 = node1.outerHTML || node1.nodeValue || "";
      const text2 = node2.outerHTML || node2.nodeValue || "";
      const isSimilar = calculateSimilarity(text1, text2) > 0.7;
      return isSimilar;
    };
    let list = [];
    const prevNodes = parseHTML(previousString);
    const newNodes = parseHTML(changeRequestText);
    const usedPrevNodes = new Set();
    const usedNewNodes = new Set();
    newNodes.forEach((newNode, j) => {
      let matched = false;
      prevNodes.forEach((prevNode, i) => {
        if (
          !usedPrevNodes.has(i) &&
          !usedNewNodes.has(j) &&
          nodesAreSimilar(prevNode, newNode)
        ) {
          const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
          const newContent = newNode?.outerHTML || newNode?.nodeValue || "";

          if (normalizeText(prevContent) || normalizeText(newContent)) {
            list.push({
              prev: prevContent,
              new: newContent,
            });
          }

          usedPrevNodes.add(i);
          usedNewNodes.add(j);
          matched = true;
        }
      });

      if (!matched) {
        const newContent = newNode?.outerHTML || newNode?.nodeValue || "";
        if (normalizeText(newContent)) {
          list.push({
            prev: "",
            new: newContent,
          });
        }
      }
    });

    prevNodes.forEach((prevNode, i) => {
      if (!usedPrevNodes.has(i)) {
        const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
        if (normalizeText(prevContent)) {
          list.push({
            prev: prevContent,
            new: "",
          });
        }
      }
    });

    // Merge consecutive entries with `prev: ""`
    const mergedList = [];
    for (let i = 0; i < list.length; i++) {
      const current = list[i];
      if (
        current.prev === "" &&
        i > 0 &&
        mergedList[mergedList.length - 1].prev === "" &&
        mergedList[mergedList.length - 1].new !== ""
      ) {
        mergedList[mergedList.length - 1].new += current.new;
      } else {
        mergedList.push(current);
      }
    }

    return mergedList;
  }
  //OLD ONE
  //   function compareHtmlStrings(previousString, changeRequestText) {
  //     const parseHTML = (htmlString) => {
  //       const parser = new DOMParser();
  //       const doc = parser.parseFromString(htmlString, "text/html");
  //       return [...doc.body.childNodes];
  //     };

  //     const normalizeText = (text) =>
  //       text.trim().replace(/\s+/g, " ").toLowerCase();

  //     const calculateSimilarity = (text1, text2) => {
  //       const words1 = normalizeText(text1).split(" ");
  //       const words2 = normalizeText(text2).split(" ");
  //       const commonWords = words1.filter((word) => words2.includes(word));
  //       return (2 * commonWords.length) / (words1.length + words2.length);
  //     };

  //     const nodesAreSimilar = (node1, node2) => {
  //       if (!node1 || !node2) return false;
  //       const text1 = node1.outerHTML || node1.nodeValue || "";
  //       const text2 = node2.outerHTML || node2.nodeValue || "";
  //       return calculateSimilarity(text1, text2) > 0.7;
  //     };

  //     let list = [];
  //     const prevNodes = parseHTML(previousString);
  //     const newNodes = parseHTML(changeRequestText);

  //     const usedPrevNodes = new Set();
  //     const usedNewNodes = new Set();

  //     prevNodes.forEach((prevNode, i) => {
  //       let matched = false;
  //       newNodes.forEach((newNode, j) => {
  //         if (
  //           !usedPrevNodes.has(i) &&
  //           !usedNewNodes.has(j) &&
  //           nodesAreSimilar(prevNode, newNode)
  //         ) {
  //           const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
  //           const newContent = newNode?.outerHTML || newNode?.nodeValue || "";

  //           if (normalizeText(prevContent) || normalizeText(newContent)) {
  //             list.push({
  //               prev: prevContent,
  //               new: newContent,
  //             });
  //           }

  //           usedPrevNodes.add(i);
  //           usedNewNodes.add(j);
  //           matched = true;
  //         }
  //       });

  //       if (!matched) {
  //         const prevContent = prevNode?.outerHTML || prevNode?.nodeValue || "";
  //         if (normalizeText(prevContent)) {
  //           list.push({
  //             prev: prevContent,
  //             new: "",
  //           });
  //         }
  //         usedPrevNodes.add(i);
  //       }
  //     });

  //     newNodes.forEach((newNode, j) => {
  //       if (!usedNewNodes.has(j)) {
  //         const newContent = newNode?.outerHTML || newNode?.nodeValue || "";
  //         if (normalizeText(newContent)) {
  //           list.push({
  //             prev: "",
  //             new: newContent,
  //           });
  //         }
  //       }
  //     });

  //     // Merge consecutive entries with `prev: ""`
  //     const mergedList = [];
  //     for (let i = 0; i < list.length; i++) {
  //       const current = list[i];
  //       if (
  //         current.prev === "" &&
  //         i > 0 &&
  //         mergedList[mergedList.length - 1].prev === "" &&
  //         mergedList[mergedList.length - 1].new !== ""
  //       ) {
  //         // Merge consecutive "prev: ''" entries
  //         mergedList[mergedList.length - 1].new += current.new;
  //       } else {
  //         mergedList.push(current);
  //       }
  //     }

  //     return mergedList;
  //   }

  //DIFF CHECKER FUNCTION
  const diffChecker = (
    previous_string,
    change_request_text,
    doc,
    searchHtml,
    isPrev,
    isNext
  ) => {
    const stripOuterTags = (htmlString) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;
      if (tempElement.children.length === 1) {
        return tempElement.children[0].innerHTML;
      }
      return tempElement.innerHTML;
    };
    const prevContent = stripOuterTags(previous_string);
    const newContent = stripOuterTags(change_request_text);
    let highlightedHTML = "";
    const dmp = new diff_match_patch();
    let diffs = dmp.diff_main(prevContent, newContent, true);
    dmp.diff_cleanupSemantic(diffs);
    let addContent = false;
    let removeContent = false;
    diffs.forEach(([type, text]) => {
      if (type === 1) {
        highlightedHTML += wrapTextContent(text, "#D8F9FF");
        addContent = true;
      } else if (type === -1) {
        highlightedHTML += wrapTextContent(text, "#D8F9FF", true);
        removeContent = true;
      } else {
        highlightedHTML += text;
      }
    });
    const tempPrevContainer = document.createElement("div");
    tempPrevContainer.innerHTML = searchHtml;
    let lastDocNode = tempPrevContainer.lastChild;
    while (
      lastDocNode &&
      (lastDocNode.textContent.trim() === "" ||
        lastDocNode.nodeName === "HR" ||
        lastDocNode.textContent.trim().length < 5)
    ) {
      lastDocNode = lastDocNode.previousSibling;
    }
    if (
      lastDocNode &&
      (lastDocNode.nodeName === "OL" ||
        lastDocNode.nodeName === "UL" ||
        lastDocNode.nodeName === "TABLE")
    ) {
      lastDocNode = lastDocNode.lastElementChild;

      while (
        lastDocNode.textContent.trim() === "" ||
        lastDocNode.nodeName === "HR" ||
        lastDocNode.textContent.trim().length < 5
      ) {
        lastDocNode = lastDocNode.previousSibling;
      }
    }
    if (lastDocNode && lastDocNode.querySelector) {
      let content = lastDocNode.textContent.trim();

      const strongElement = lastDocNode.querySelector("strong");
      if (strongElement) {
        const nextSiblingText = strongElement.nextSibling
          ? strongElement.nextSibling.textContent.trim()
          : "";
        const previousSiblingText = strongElement.previousSibling
          ? strongElement.previousSibling.textContent.trim()
          : "";

        if (nextSiblingText.length >= previousSiblingText.length) {
          lastDocNode.textContent = nextSiblingText || content;
        } else {
          lastDocNode.textContent = previousSiblingText || content;
        }
      }

      const emElement = lastDocNode.querySelector("em");
      if (emElement) {
        const nextSiblingText = emElement.nextSibling
          ? emElement.nextSibling.textContent.trim()
          : "";
        const previousSiblingText = emElement.previousSibling
          ? emElement.previousSibling.textContent.trim()
          : "";

        if (nextSiblingText.length >= previousSiblingText.length) {
          lastDocNode.textContent = nextSiblingText || content;
        } else {
          lastDocNode.textContent = previousSiblingText || content;
        }
      }
    }
    if (highlightedHTML === prevContent) {
      return;
    }
    if (removeContent) {
      replaceMatchingText(
        doc.body,
        lastDocNode?.textContent || "",
        highlightedHTML,
        isPrev,
        isNext
      );
    }
    if (addContent && !removeContent) {
       handleAddedContent(
        doc.body,
        lastDocNode?.textContent || "",
        highlightedHTML,
        isPrev,
        isNext
      );
    }

    if (!removeContent && !addContent) {
      return;
    }
    const elements = "p, h1, h2, h3, span, div, li, ul, ol";
    removeElementsWithOnlyBr(doc, elements);
  };

  //Replace text
  const replaceMatchingText = (node, searchText, replacementHTML, isPrev) => {
    const walker = document.createTreeWalker(
      node,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );

    const normalizedSearchText = cleanText(searchText);
    let found = false;

    while (walker.nextNode()) {
      let currentNode = walker.currentNode;
      const currentText = cleanText(currentNode.textContent);
      if (currentText && currentText.includes(normalizedSearchText)) {
        let parentElement = currentNode.parentNode;
        let parentText = cleanText(parentElement.textContent);
        if (parentText.includes(normalizedSearchText)) {
          const style = currentNode.parentNode.style.cssText || "";
          const tempContainer = document.createElement("span");
          tempContainer.innerHTML = replacementHTML;
          tempContainer.style.cssText =
            "display: inline; font-size: 0; margin: 0; padding: 0;";
          Array.from(tempContainer.childNodes).forEach((child) => {
            applyStyleRecursively(child, style);
          });
          if (
            parentElement.nodeName === "LI" ||
            parentElement.nodeName === "EM" ||
            parentElement.nodeName === "STRONG"
          ) {
            parentElement = parentElement.parentNode;
          }
          if (parentElement.nodeName === "P") {
            const nodeParent = parentElement.parentElement;
            if (nodeParent.nodeName === "LI") {
              parentElement = parentElement.parentNode;
              parentElement = parentElement.parentNode;
            }
          }
          parentElement.innerHTML = isPrev
            ? parentElement.innerHTML + tempContainer.innerHTML
            : tempContainer.innerHTML;
          found = true;
          break;
        }
      }
    }

    return found;
  };

  const cleanText = (text) =>
    text
      .replace(/\n/g, "")
      .replace(/[-"’'“” ]/g, "")
      .replace(/\s+/g, "")
      .trim()
      .normalize();

  //GET TEXTCONTENT AFTER CLEARING ALL SPACES AND NUMERIC SYMBOL
  // const getText = (htmlString) => {
  //   // Create a temporary element to parse the HTML string and remove HTML tags
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = htmlString;

  //   // Extract the text content after removing HTML tags
  //   let textContent = tempElement.textContent || tempElement.innerText || "";

  //   // Remove unwanted symbols (non-alphanumeric characters) and normalize
  //   return textContent
  //     .replace(/[\n\r\t]/g, "") // Remove newlines, tabs, and carriage returns
  //     .replace(/[^a-zA-Z0-9]/g, "") // Remove anything that's not alphanumeric (letters and numbers)
  //     .normalize() // Normalize characters (e.g., combining accents to single characters)
  //     .trim();
  // };

  //Add New Content

  const applyStyleRecursively = (node, style) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.nodeName === "MARK") {
        return;
      }
      if (node.nodeName === "CODE") {
        //REMOVED MARK TAG
        return;
      } else if (node.nodeName === "STRONG") {
        const fontStyles = extractFontStyles(style);
        node.setAttribute("style", fontStyles);
      } else if (
        node.nodeName === "H1" ||
        node.nodeName === "H2" ||
        node.nodeName === "H3" ||
        node.nodeName === "H4"
      ) {
        const fontStyles = extractFontFamily(style);
        node.setAttribute("style", fontStyles);
      } else {
        const fontStyles = extractAllStyles(style);

        node.setAttribute("style", fontStyles);
      }
    }
    Array.from(node.childNodes).forEach((child) => {
      applyStyleRecursively(child, style);
    });
  };
  const extractAllStyles = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter((rule) => rule.length > 0);

    return styleObj.join("; ");
  };

  const extractFontStyles = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter(
        (rule) => rule.startsWith("font-family") || rule.startsWith("font-size")
      );
    return styleObj.join("; ");
  };
  const extractFontFamily = (style) => {
    const styleObj = style
      .split(";")
      .map((rule) => rule.trim())
      .filter((rule) => rule.startsWith("font-family"));
    return styleObj.join("; ");
  };

  //LEVENSHTEIN METHOD
  const levenshtein = (a, b) => {
    if (!a || !b) {
      return Math.max(a ? a.length : 0, b ? b.length : 0); // Return the length of the string if one is empty
    }

    // Create a 2D array with dimensions (a.length + 1) x (b.length + 1)
    const tmp = [];

    // Initialize the 2D array: each row should be an array of size (a.length + 1)
    for (let i = 0; i <= b.length; i++) {
      tmp[i] = [];
      for (let j = 0; j <= a.length; j++) {
        tmp[i][j] = 0; // Initialize all values to 0
      }
    }

    // Initialize the first row and the first column
    for (let i = 0; i <= a.length; i++) {
      tmp[0][i] = i; // First row: [0, 1, 2, ..., a.length]
    }

    for (let i = 0; i <= b.length; i++) {
      tmp[i][0] = i; // First column: [0, 1, 2, ..., b.length]
    }

    // Fill in the rest of the array with minimum cost calculations
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        tmp[i][j] = Math.min(
          tmp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1), // substitution
          tmp[i][j - 1] + 1, // insertion
          tmp[i - 1][j] + 1 // deletion
        );
      }
    }
    return tmp[b.length][a.length];
  };

  const calculateSimilarity = (a, b) => {
    const maxLen = Math.max(a.length, b.length);
    const levDist = levenshtein(a, b);

    return (maxLen - levDist) / maxLen;
  };
  //HANDLE ADDED NEW CONTENT
  // const handleAddedContent = (node, searchText, addedText, isPrev, isNext) => {
  //   const newNode = document.createElement("span");
  //   newNode.innerHTML = addedText;
  //   const normalizedSearchText = cleanText(searchText);
  //   const walker = document.createTreeWalker(
  //     node,
  //     NodeFilter.SHOW_TEXT,
  //     null,
  //     false
  //   );

  //   let found = false;
  //   const similarityThreshold = 0.95;
  //   while (walker.nextNode()) {
  //     const currentNode = walker.currentNode;
  //     const currentText = cleanText(currentNode.textContent);

  //     const similarity = calculateSimilarity(currentText, normalizedSearchText);

  //     if (similarity >= similarityThreshold) {
  //       const style =
  //         currentNode.parentNode.style.cssText ||
  //         currentNode.parentNode.parentNode.style.cssText ||
  //         "";
  //       Array.from(newNode.childNodes).forEach((child) => {
  //         applyStyleRecursively(child, style);
  //       });
  //       let parentElement = currentNode.parentNode;
  //       if (
  //         parentElement.nodeName === "LI" ||
  //         parentElement.nodeName === "TB" ||
  //         parentElement.nodeName === "OL" ||
  //         parentElement.nodeName === "UL" ||
  //         parentElement.nodeName === "SPAN"
  //       ) {
  //         parentElement = parentElement.parentNode;
  //         if (
  //           newNode.firstChild.nodeName === "MARK" &&
  //           parentElement.nodeName === "LI"
  //         ) {
  //           if (isPrev) {
  //             parentElement.parentNode.insertBefore(
  //               newNode,
  //               parentElement.nextElementSibling
  //             );
  //           } else if (isNext) {
  //             parentElement.parentNode.insertBefore(newNode, parentElement);
  //           } else {
  //             parentElement.innerHTML = newNode.innerHTML;
  //           }
  //         } else {
  //           if (isPrev) {
  //             parentElement.parentNode.insertBefore(
  //               newNode,
  //               parentElement.nextElementSibling
  //             );
  //           } else if (isNext) {
  //             parentElement.parentNode.insertBefore(newNode, parentElement);
  //           } else {
  //             parentElement.innerHTML = newNode.innerHTML;
  //           }
  //         }
  //       } else {
  //         if (isPrev) {
  //           parentElement.parentNode.insertBefore(
  //             newNode,
  //             parentElement.nextElementSibling
  //           );
  //         } else if (isNext) {
  //           parentElement.parentNode.insertBefore(newNode, parentElement);
  //         } else {
  //           parentElement.innerHTML = newNode.innerHTML;
  //         }
  //       }

  //       found = true;
  //       break;
  //     }
  //   }

  //   return found;
  // };
  const handleAddedContent = (node, searchText, addedText, isPrev, isNext) => {
    const newNode = document.createElement("span");
    newNode.innerHTML = addedText;

    const normalizedSearchText = cleanText(searchText);
    const walker = document.createTreeWalker(
      node,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );

    let found = false;
    const similarityThreshold = 0.95;
    const getMainParentNode = (currentNode) => {
      while (
        currentNode &&
        currentNode.parentNode.nodeName !== "DIV" &&
        currentNode.parentNode.nodeName !== "BODY"
      ) {
        currentNode = currentNode.parentNode;
      }
      return currentNode;
    };

    // Traverse through text nodes
    while (walker.nextNode()) {
      const currentNode = walker.currentNode;
      const currentText = cleanText(currentNode.textContent);

      const similarity = calculateSimilarity(currentText, normalizedSearchText);

      if (similarity >= similarityThreshold) {
        const mainParent = getMainParentNode(currentNode);
        const style = currentNode.parentNode.style.cssText || "";
       applyStyleRecursively(newNode, style);
        Array.from(newNode.childNodes).forEach((child) => {
          applyStyleRecursively(child, style);
        });

        // Handle insertion logic
        if (isPrev) {
          mainParent.parentNode.insertBefore(newNode, mainParent.nextSibling);
        } else if (isNext) {
          mainParent.parentNode.insertBefore(newNode, mainParent);
        } else {
          mainParent.innerHTML = newNode.innerHTML;
        }
   
        found = true;
        break;
      }
    }

    return found;
  };

  const wrapTextContent = (content, color, isRemoved = false) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const wrapTextNode = (node) => {
      if (
        node.nodeType === Node.TEXT_NODE &&
        node.textContent.trim().length > 0
      ) {
        const markedText = document.createElement("mark");
        markedText.style.backgroundColor = color;
        if (isRemoved) {
          markedText.style.textDecoration = "line-through";
        }
        markedText.style.fontFamily = "inherit";
        markedText.style.fontSize = "inherit";
        markedText.style.color = "inherit";
        markedText.textContent = node.textContent;
        node.replaceWith(markedText);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(wrapTextNode);
      }
    };

    Array.from(tempDiv.childNodes).forEach(wrapTextNode);
    return tempDiv.innerHTML;
  };

  //REMOVE EXTRA SPACES
  function removeElementsWithOnlyBr(doc) {
    const selectors = "p, h1, h2, h3, span, div, li, ul, ol";
    const elements = doc.querySelectorAll(selectors);
    elements.forEach((element) => {
      const children = Array.from(element.childNodes);

      const hasNoTextContent =
        element.textContent.trim() === "" ||
        children.every(
          (child) =>
            (child.nodeType === Node.ELEMENT_NODE && child.nodeName === "BR") ||
            (child.nodeType === Node.TEXT_NODE &&
              child.textContent.trim() === "")
        );
      if (hasNoTextContent) {
        element.remove();
      }
    });
  }

  //REMOVE MARK TAGS FROM DOC
  const clearPreviousHighlights = (doc) => {
    const marks = doc.querySelectorAll("mark");
    marks.forEach((mark) => {
      const isRemoved = mark.style.textDecoration === "line-through";
      const parent = mark.parentNode;
      if (isRemoved) {
        while (mark.firstChild) {
          parent.insertBefore(mark.firstChild, mark);
        }
        mark.remove();
      } else if (!isRemoved) {
        mark.remove();
      }
    });
    const elements = "p, h1, h2, h3, span, div, li, ul, ol";
    removeElementsWithOnlyBr(doc, elements);
  };

  //Handle Resolve Warning Pop Up
  const handleOpenResolveWarning = async () => {
    const data = await apiService.pendingRecommendation(requestDataRef.current.request_id);
    if (data.isPending) {
      setShowResolveWarning(true);
    } else {
      await handleResolveByte();
      setShowChangeRequest(false);
      navigate(`/home/document/${activeDocIdRef.current}`);
    }
  };

  const handleCloseResolveWarning = () => {
    setShowResolveWarning(false);
  };

  //Handle Resolve Byte
  const handleResolveByte = async () => {
    await apiService.resolveByte(requestDataRef.current.request_id);
    handleCloseResolveWarning();
    setShowChangeRequest(false);
    navigate(`/home/document/${activeDocIdRef.current}`);
  };

  //Handle Navigation
  const handlePrevious = () => {
    if (currentRecommendationIndex > 0) {
      updateModel(currentRecommendationIndex - 1, "previous");
    }
  };

  const handleNext = () => {
    if (currentRecommendationIndex < sortedRecommendationData.length - 1) {
      updateModel(currentRecommendationIndex + 1, "next");
    }
  };

  const updateModel = async (newIndex, direction) => {
    let recommendation = sortedRecommendationData[newIndex];
    if (!recommendation) {
      return;
    }
    const currentDocId = recommendation.doc_id;
    if (String(activeDocIdRef.current) !== String(currentDocId)) {
      activeDocIdRef.current = currentDocId;
      if (direction === "next") {
       setActiveRecommendationData(null);
      } else {
        setIsProcessingComplete(false);
        setActiveRecommendationData(recommendation);
      }
      const container = containerRef.current;
      if (container) {
        container.scrollTop = 0;
      }
      debouncedUpload.cancel();
      await handleDocumentChange(currentDocId, recommendation.doc_content);
    } else {
      setActiveRecommendationData(recommendation);
    }
  };

  // Function to handle document content change and navigation
  const handleDocumentChange = async (docId, docContentUrl) => {
    handleUpdate();
    navigate(`/home/${byteId}/document-edit/${docId}`, { replace: true });
    const newHtmlContent = await fetchHTMLContent(docContentUrl);
    setModel(newHtmlContent);
    setFileName(docContentUrl.substring(docContentUrl.lastIndexOf("/") + 1));
    dBModelRef.current = newHtmlContent;
  };

  const handleOnTap = () => {
    setShowChangeRequest(false);
    setCurrentRecommendationIndex(-1);
    navigate(`/home/document/${id}`, {
      replace: true,
    });
  };

  //LOADING - Skeleton loading
  if (isLoading) {
    return (
      <div id="editor" className="froala-editor-section">
        <div id="toolbar-container" className="toolbar-container"></div>
        <div style={{ paddingBottom: "15px" }}>
          <EditorSkeleton
            height="40px"
            borderRadius="100px"
            padding="4px 0px"
          />
        </div>
        <div className="editor-suggestion">
          <div className="change-request">
            <EditorSkeleton width="100%" height="100vh" borderRadius="4px" />
          </div>
          <RecommendationSkeletonLoader count={4} />
        </div>
      </div>
    );
  }

  //Main Body
  return (
    <div className="doc-editor">
      <ResolveChangeRequestPopUp
        isVisible={showResolveWarning}
        onClickLButton={handleResolveByte}
        onClickRButton={handleCloseResolveWarning}
        onClose={handleCloseResolveWarning}
        title="Open AiEdits Pending"
        subtitle="Do you still wish to resolve the Change Request?"
        lButtonText="Resolve CR"
        rButtonText="View AiEdits"
      />
      <div id="editor" className="froala-editor-section fade-in">
        {/* Toolbar Container */}
        <div id="toolbar-container" className="toolbar-container"></div>
        <NotificationBar isVisible={showAutoRejectWarning} />
        <div className="editor-suggestion" ref={containerRef}>
          <div className="change-request">
            {showChangeRequest && recommendationData && (
              <ChangeRequest
                onResolve={handleOpenResolveWarning}
                width={editorWidth ?? 300}
                requester={requestDataRef.current?.sender ?? "loading"}
                date={requestDataRef.current?.date_time ?? "Date"}
                message={requestDataRef.current?.request_text ?? "Change byte"}
                aiEdits={
                  // activeRecommendationData
                  //   ?
                  `${
                    sortedRecommendationData &&
                    sortedRecommendationData.length > 0
                      ? currentRecommendationIndex + 1
                      : 0
                  }/${sortedRecommendationData?.length ?? 0}`
                  // : "0/0"
                }
                onPrevious={handlePrevious}
                onNext={handleNext}
                onTap={handleOnTap}
              />
            )}

            {isLoading ? (
              <EditorSkeleton />
            ) : (
              // Froala Editor
              <div ref={editorRef}>
                <FroalaEditorComponent
                  tag="textarea"
                  model={model}
                  onModelChange={handleModelChange}
                  config={{
                    toolbarSticky: true,
                    editorClass: "froala-editor",
                    spellcheck: true,
                    attribution: true,
                    heightMin: 200,
                    heightMax: 800,
                    placeholderText: "Edit your content here...",
                    toolbarVisibleWithoutSelection: true,
                    charCounterCount: true,
                    toolbarContainer: "#toolbar-container",
                    pluginsEnabled: [
                      "fullscreen",
                      "codeBeautifier",
                      "align",
                      "image",
                      "link",
                      "fontFamily",
                      "fontSize",
                      "specialCharacters",
                      "lists",
                      "table",
                      "codeView",
                      "colors",
                      "backgroundColor",
                      "undo",
                      "redo",
                      "paragraphFormat",
                      "inlineStyle",
                      "inlineClass",
                      "clearFormatting",
                      "increaseIndent",
                    ],
                    toolbarButtons: {
                      moreText: {
                        buttons: [
                          "paragraphFormat",
                          "fontFamily", //default Manrope
                          "fontSize", //default sie 11
                          "|",
                          "bold",
                          "italic",
                          "underline",
                          "textColor",
                          "backgroundColor",
                          "strikeThrough",
                          "html",
                          "subscript",
                          "superscript",
                          "specialCharacters",
                        ],
                        align: "left",
                        buttonsVisible: 6,
                      },
                      moreParagraph: {
                        buttons: [
                          // "|",
                          // "alignLeft",
                          // "alignCenter",
                          // "alignRight",
                          "insertCode",

                          "alignLeft",
                          "alignCenter",
                          "alignRight",
                          "alignJustify",
                          "indent",
                          "outdent",
                        ],
                        align: "left",
                        buttonsVisible: 0,
                      },
                      moreRich: {
                        buttons: [
                          "formatUL",
                          "formatOL",
                          "formatOLSimple",
                          "|",
                          "insertLink",
                          "insertImage",
                          "insertTable",
                        ],
                        align: "left",
                        buttonsVisible: 6,
                      },
                      moreMisc: {
                        buttons: ["undo", "redo", "formatPainter"],
                        align: "right",
                        buttonsVisible: 3,
                      },
                    },
                    linkAlwaysBlank: true,
                    showColorsPopup: true,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    paragraphFormatSelection: true,
                    events: {
                      initialized: function () {
                        const secondToolbar =
                          document.querySelector(".fr-second-toolbar");
                        if (secondToolbar) {
                          secondToolbar.remove();
                        }
                      },
                      "paste.before": function (clipboardEvent) {
                        const userSelection = window.getSelection();
                        if (
                          userSelection &&
                          userSelection.anchorNode &&
                          userSelection.anchorNode.parentNode.nodeName ===
                            "CODE"
                        ) {
                          clipboardEvent.preventDefault();
                          const clipboardData = clipboardEvent.clipboardData;
                          //  || window.clipboardData //Temp comment
                          const pastedHTML = clipboardData.getData("text/html");
                          const pastedText =
                            clipboardData.getData("text/plain");
                          const contentToInsert = pastedHTML || pastedText;
                          if (contentToInsert.trim() === "") {
                            return false;
                          }
                          const codeNode = userSelection.anchorNode.parentNode;
                          const existingContent = codeNode.innerHTML;
                          const updatedContent = `${existingContent}${contentToInsert}`;
                          codeNode.innerHTML = updatedContent;

                          return false;
                        }
                      },

                      "paste.after": function () {
                        const updatedModel = this.html.get();
                        handleContentChange(
                          updatedModel,
                          activeDocIdRef.current
                        );
                      },
                      keydown: function (e) {
                        try {
                          if (
                            e.key === "Backspace" ||
                            e.key === "Delete" ||
                            e.key === "Enter"
                          ) {
                            const updatedModel = this.html.get();
                            handleContentChange(
                              updatedModel,
                              activeDocIdRef.current
                            );
                          }
                          const editor = this;
                          const keyCode = e.which || e.keyCode;

                          if (keyCode === 9) {
                            e.preventDefault();
                            const range = editor.selection.ranges(0);

                            if (range && range.startContainer) {
                              const listItem = editor.selection.element();
                              if (listItem.tagName === "LI") {
                                if (e.shiftKey) {
                                  editor.commands.outdent();
                                } else {
                                  editor.commands.indent();
                                }
                              }
                            }
                          }
                          if (keyCode === 32) {
                            const range = editor.selection.ranges(0);
                            if (range) {
                              const textBefore =
                                range.startContainer.textContent
                                  .substring(0, range.startOffset)
                                  .trim();
                              if (textBefore === "1.") {
                                e.preventDefault();
                                range.startContainer.textContent =
                                  range.startContainer.textContent.replace(
                                    "1.",
                                    ""
                                  );
                                editor.html.insert("<ol><li><br></li></ol>");

                                // const list = editor.el.querySelector("ol li");
                              }
                            }

                            if (range) {
                              const textBefore =
                                range.startContainer.textContent
                                  .substring(0, range.startOffset)
                                  .trim();
                              if (textBefore === "*") {
                                e.preventDefault();
                                range.startContainer.textContent =
                                  range.startContainer.textContent.replace(
                                    "*",
                                    ""
                                  );
                                editor.html.insert("<ul><li></li></ul>");
                                // const list = editor.el.querySelector("ul li");
                              }
                            }
                          }
                          if (keyCode === 8) {
                            const selection = editor.selection;
                            const element = selection.element();
                            if (element && element.tagName === "BR") {
                              const listItem = element.parentNode;
                              if (listItem.tagName === "LI") {
                                const list = listItem.closest("ol");
                                const isEmpty =
                                  listItem.innerHTML.trim() === "<br>";
                                const isSingleItemList =
                                  list.querySelectorAll("li").length === 1;
                                if (isEmpty && isSingleItemList) {
                                  e.preventDefault();
                                  const parent = list.parentNode;
                                  const pNode = document.createElement("p");
                                  pNode.innerHTML = "1.&nbsp;";
                                  parent.replaceChild(pNode, list);
                                  const range = document.createRange();
                                  const sel = window.getSelection();
                                  const textNode = pNode.firstChild;
                                  range.setStart(
                                    textNode,
                                    textNode.textContent.length
                                  );
                                  range.collapse(true);
                                  sel.removeAllRanges();
                                  sel.addRange(range);
                                }
                              }
                            }
                          }
                        } catch (error) {}
                      },
                      input: function (inputEvent) {
                        const updatedModel = this.html.get();
                        handleContentChange(
                          updatedModel,
                          activeDocIdRef.current
                        );
                      },
                      "image.removed": function ($img) {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "image.resize": function ($img) {
                        const updatedModel = this.html.get();
                        handleContentChange(
                          updatedModel,
                          activeDocIdRef.current
                        );
                      },
                      // "window.copy": function () {
                      //   const recommendationElements =
                      //     document.querySelectorAll(
                      //       "[data-location^='recommendation_']"
                      //     );
                      //   recommendationElements.forEach(
                      //     (el) => (el.style.display = "none")
                      //   );

                      //   setTimeout(() => {
                      //     processAllRecommendations();
                      //   }, 100);
                      // },
                      "commands.before": function (cmd, param1, param2) {
                       try{ if (cmd === "html") {
                          const userSelection = window.getSelection();
                          if (
                            userSelection &&
                            userSelection.rangeCount &&
                            userSelection.toString().trim() !== ""
                          ) {
                            const range = userSelection.getRangeAt(0);
                            const selectedNode = range.startContainer;
                            const parentNode = selectedNode.parentNode;

                            if (selectedNode.nodeType === 3) {
                              if (
                                parentNode &&
                                parentNode.nodeName === "CODE"
                              ) {
                                if (
                                  range.toString().trim() !==
                                  parentNode.textContent.trim()
                                ) {
                                  return false;
                                }
                                const cleanedText = parentNode.textContent;
                                this.html.insert(cleanedText);
                              } else {
                                //OLD
                                // const wrappedCode = `<code>${userSelection.toString()}</code>`;
                                // this.html.insert(wrappedCode);
                                const clonedContent = range.cloneContents();
                                const wrapper = document.createElement("div");
                                wrapper.appendChild(clonedContent);
                                const wrappedCode = `<code>${wrapper.innerHTML}</code>`;
                                this.html.insert(wrappedCode);
                              }
                            } else if (
                              parentNode &&
                              parentNode.nodeName === "CODE"
                            ) {
                              return false;
                            } else {
                              const codeBlock = "<code> </code>";
                              this.html.insert(codeBlock);
                            }
                          } else {
                            const selectionParent = userSelection.anchorNode
                              ? userSelection.anchorNode.parentNode
                              : null;
                            if (
                              !selectionParent ||
                              selectionParent.nodeName !== "CODE"
                            ) {
                              const codeBlock = "<code> </code>";
                              this.html.insert(codeBlock);
                            } else {
                            }
                          }

                          return false;
                        }
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      }catch(e){
                        
                      }},
                      "commands.after": function (cmd, param1, param2) {
                        const updatedModel = this.html.get();
                        handleContentChange(updatedModel);
                      },
                      "image.beforeUpload": async function (images) {
                        if (images.length) {
                          const imageFile = images[0];
                          try {
                            const response = await apiService.uploadImage(
                              imageFile,
                              id
                            );
                            if (response.status === "success") {
                              // this.image.insert(
                              //   response.imageUrl,
                              //   null,
                              //   null,
                              //   this.image.get()
                              // );
                              const cursorPosition = this.selection.get();
                              this.image.insert(
                                response.imageUrl,
                                null,
                                null,
                                this.image.get()
                              );
                              
                              const updatedModel = this.html.get();
                              handleContentChange(
                                updatedModel,
                                activeDocIdRef.current
                              );
                            } else {
                              return false;
                            }
                          } catch (error) {
                            console.log("HERE IN catch", error);
                          }
                          return false;
                        }
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
          {isSuggestionLoading ? (
            <RecommendationSkeletonLoader count={5} />
          ) : (
            <SuggestionList
              sortedRecommendationData={sortedRecommendationData}
              id={id}
              acceptedArray={acceptedArray}
              rejectedArray={rejectedArray}
              activeRecommendationData={activeRecommendationData}
              addText={addText}
              rejectRecommendation={rejectRecommendation}
              handleNavigation={handleNavigation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FunctionalEditor;

const FallbackUI = ({ error, retry }) => {
    return (
      <div style={{ textAlign: "center", padding: "20px", color: "#ff4d4f" }}>
        <h2>Oops! Something went wrong 😔</h2>
        <p>{error}</p>
        <button onClick={retry} style={{ padding: "10px 20px", cursor: "pointer" }}>
          Retry
        </button>
      </div>
    );
  };

  export default FallbackUI;
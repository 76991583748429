import React, { useEffect, useRef, useState } from "react";
import "./editor-style.css";
import SvgCloseCross from "../../icons/CloseCross";
import SvgDoneCheck from "../../icons/DoneCheck";
import { apiService } from "../../services/apiService";
import { toast } from "react-toastify";
import { IoIosInformationCircleOutline } from "react-icons/io";
import InfoTooltip from "../PopUps/InfoToolTip";
import { diffWords } from "diff";

const SuggestionCardComponent = ({
  num,
  recommendationData,
  onTapAccept,
  updateAcceptStatus,
  onTapReject,
  isRecommendationAccepted,
  isRecommendationRejected,
  isActive,
  isVisible,
  onCoverTap,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!isActive && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [isActive]);
  const tooltipId = "info-tooltip";

  const [isAccepted, setIsAccepted] = useState(isRecommendationAccepted);
  const [isRejected, setIsRejected] = useState(isRecommendationRejected);
  // const [isAcceptLoading, setIsAccpetLoading] = useState(false);
  // const [isRejectLoading, setIsRejectLoading] = useState(false);

  const {
    id,
    section_main_heading1,
    change_request_text,
    previous_string,
    prediction,
  } = recommendationData;

  // const generateChangeSummary = (content, previousString) => {
  //   if (!previousString) return "No changes detected.";
  //   const stripHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "").trim();
  //   const cleanContent = stripHtmlTags(content);
  //   const cleanPreviousString = stripHtmlTags(previousString);
  //   const diff = diffWords(cleanPreviousString, cleanContent);

  //   // Initialize variables to collect changes
  //   const deletedWords = [];
  //   const addedWords = [];
  //   const replacedWords = [];
  //   diff.forEach((part, index) => {
  //     if (part.removed) {
  //       if (diff[index + 1] && diff[index + 1].added) {
  //         // Replaced word
  //         replacedWords.push({
  //           from: part.value.trim(),
  //           to: diff[index + 1].value.trim(),
  //         });
  //       } else {
  //         // Deleted word
  //         deletedWords.push(part.value.trim());
  //       }
  //     } else if (part.added) {
  //       if (!(diff[index - 1] && diff[index - 1].removed)) {
  //         // Added word
  //         addedWords.push(part.value.trim());
  //       }
  //     }
  //   });

  //   // Utility to truncate long strings
  //   const truncateLongText = (text) => {
  //     if (text.length > 100) {
  //       return `<span>${text.slice(0, 100)}....</span>`;
  //     }
  //     return text;
  //   };

  //   // Build the summary sections dynamically
  //   const summarySections = [];
  //   if (deletedWords.length > 0) {
  //     summarySections.push(
  //       `<div><strong>Delete:</strong> <i>"${truncateLongText(
  //         deletedWords.join(", ")
  //       )}"</i></div>`
  //     );
  //   }
  //   if (replacedWords.length > 0) {
  //     summarySections.push(
  //       `<div><strong>Replace:</strong><ul>${replacedWords
  //         .map(
  //           (change) =>
  //             `<li><i>"${truncateLongText(
  //               change.from
  //             )}" with "${truncateLongText(change.to)}"</i></li>`
  //         )
  //         .join("")}</ul></div>`
  //     );
  //   }
  //   if (addedWords.length > 0) {
  //     summarySections.push(
  //       `<div><strong>Add:</strong> <i> "${truncateLongText(
  //         addedWords.join(", ")
  //       )}"</i></div>`
  //     );
  //   }
  //   return summarySections.length > 0
  //     ? summarySections.join("")
  //     : "No changes detected.";
  // };
  const generateChangeSummary = (content, previousString) => {
    if (!previousString) return "No changes detected.";

    const stripHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "").trim();
    const cleanContent = stripHtmlTags(content);
    const cleanPreviousString = stripHtmlTags(previousString);
    const diff = diffWords(cleanPreviousString, cleanContent);

    // Initialize variables to collect changes
    const deletedWords = [];
    const addedWords = [];
    const replacedWords = [];
    const addedWordDetails = []; // For contextual additions

    diff.forEach((part, index) => {
      if (part.removed) {
        if (diff[index + 1] && diff[index + 1].added) {
          // Replaced word
          replacedWords.push({
            from: part.value.trim(),
            to: diff[index + 1].value.trim(),
          });
        } else {
          // Deleted word
          deletedWords.push(part.value.trim());
        }
      } else if (part.added) {
        if (!(diff[index - 1] && diff[index - 1].removed)) {
          // Added word
          addedWords.push(part.value.trim());

          // Collect context for the addition
          const before = diff[index - 1] ? diff[index - 1].value.trim() : "";
          addedWordDetails.push({
            after: before,
            added: part.value.trim(),
          });
        }
      }
    });

    // Utility to truncate long strings
    const truncateLongText = (text) => {
      if (text.length > 100) {
        return `<span>${text.slice(0, 100)}....</span>`;
      }
      return text;
    };

    // Build the summary sections dynamically
    const summarySections = [];

    if (deletedWords.length > 0) {
      summarySections.push(
        `<div><strong>Delete:</strong><ul>${deletedWords
          .map((word) => `<li><i>"${truncateLongText(word)}"</i></li>`)
          .join("")}</ul></div>`
      );
    }

    if (replacedWords.length > 0) {
      summarySections.push(
        `<div><strong>Replace:</strong><ul>${replacedWords
          .map(
            (change) =>
              `<li><i>"${truncateLongText(
                change.from
              )}" with "${truncateLongText(change.to)}"</i></li>`
          )
          .join("")}</ul></div>`
      );
    }

    if (addedWords.length > 0) {
      summarySections.push(
        `<div><strong>Add:</strong><ul>${addedWordDetails
          .map(
            (detail) => `<li>${truncateLongText(detail.added)}</li>`
            // `<li><i>After word "${truncateLongText(
            //   detail.after
            // )}" added "${truncateLongText(detail.added)}"</i></li>`
          )
          .join("")}</ul></div>`
      );
    }

    return summarySections.length > 0
      ? summarySections.join("")
      : "No changes detected.";
  };

  let filteredContent = generateChangeSummary(
    change_request_text,
    previous_string
  );
  const suggestionTitle = filteredContent.includes("Delete:")
    ? "Revisions"
    : "Additions";
  // Handle Recommendation Action
  const handleModifyRecommendation = async (action) => {
    const response = await apiService.acceptRejectByte(
      recommendationData.byte_id,
      recommendationData.doc_id,
      "h1",
      section_main_heading1,
      recommendationData.previous_string,
      action,
      id,
      "Update using button"
    );
    return response;
  };

  if (isVisible) {
    return null;
  }
  return (
    <div
      className={`suggestion-container ${isActive ? "active" : ""} 
      ${isAccepted ? "accepted" : ""} 
      ${isRejected ? "rejected" : ""}`}
      onClick={() => {
        if (!isActive) {
          onCoverTap();
        }
      }}
    >
      <InfoTooltip id={tooltipId} />
      <div className="suggestion-title">
        <div className="suggestion-header">
          <span className="suggestion-num">{num}</span>
          <span className="suggestion-title">{suggestionTitle}</span>{" "}
          <IoIosInformationCircleOutline
            className="info-icon"
            data-tooltip-id={tooltipId}
            data-tooltip-content={prediction}
          />
        </div>
        {isAccepted && (
          <button className="final-button-accept">Accepted</button>
        )}
        {isRejected && (
          <button className="final-button-reject">Rejected</button>
        )}
        {isActive && !isAccepted && !isRejected && (
          <div className="suggestion-action">
            <SvgDoneCheck
              className="final-icon"
              onClick={async (e) => {
                e.stopPropagation();
                // setIsAccpetLoading(true);
                const acceptResult = await onTapAccept();
                if (acceptResult === true) {
                  try {
                    const response = await handleModifyRecommendation(
                      "ACCEPTED"
                    );
                    if (response?.status === "success") {
                      setIsAccepted(true);
                      updateAcceptStatus();
                    } else {
                    }
                  } catch (e) {
                    toast.error("Please try again.");
                  } finally {
                    // setIsAccpetLoading(false);
                  }
                } else {
                  toast.error("Text not found");
                  // setIsAccpetLoading(false);
                }
              }}
            />
            <SvgCloseCross
              className="grey-icon"
              onClick={async (e) => {
                e.stopPropagation();
                // setIsRejectLoading(true);
                try {
                  const response = await handleModifyRecommendation("REJECTED");
                  if (response?.status === "success") {
                    setIsRejected(true);
                    onTapReject();
                  } else {
                  }
                } catch (error) {
                  toast.error("An error occurred. Please try again.");
                } finally {
                  // setIsRejectLoading(false);
                }
              }}
            />
          </div>
        )}
      </div>
      <div
        className={`suggestion-content ${isActive ? "active" : ""}`}
        ref={contentRef}
      >
        <div dangerouslySetInnerHTML={{ __html: filteredContent }} />
      </div>
    </div>
  );
};

export default SuggestionCardComponent;

import SuggestionCardComponent from "./SuggestionCardComponent";
import "./editor-style.css";
import React, {
    useRef,
    useEffect,
  } from "react";
const SuggestionList = ({
    sortedRecommendationData,
    id,
    acceptedArray,
    rejectedArray,
    activeRecommendationData,
    addText,
    rejectRecommendation,
    handleNavigation,
  }) => {
    const cardRefs = useRef([]);
  
  
    //Overlap checker
    useEffect(() => {
      const handleOverlapAdjustments = () => {
        if (!activeRecommendationData) return;
  
        // Reset positions to recommendationY
        cardRefs.current.forEach((card, index) => {
          if (card) {
            card.style.top = `${sortedRecommendationData[index].y}px`;
          }
        });
  
        // Check for overlapping and adjust only if needed
        const positions = cardRefs.current.map((card, index) => {
          if (card) {
            const rect = card.getBoundingClientRect();
            return {
              y: parseFloat(card.style.top || "0"),
              height: rect.height,
            };
          }
          return null;
        });
  
        let hasOverlap = false;
  
        for (let i = 0; i < positions.length - 1; i++) {
          const currentCard = positions[i];
          const nextCard = positions[i + 1];
  
          if (!currentCard || !nextCard) continue;
  
          const currentBottom = currentCard.y + currentCard.height;
          if (currentBottom > nextCard.y) {
            hasOverlap = true;
            break;
          }
        }
  
        if (!hasOverlap) return;
  
        // FOR Overlapping
        for (let i = 0; i < positions.length - 1; i++) {
          const currentCard = positions[i];
          const nextCard = positions[i + 1];
  
          if (!currentCard || !nextCard) continue;
  
          const currentBottom = currentCard.y + currentCard.height;
  
          if (currentBottom > nextCard.y) {
            const offset = currentBottom - nextCard.y + 10; 
            nextCard.y += offset;
            cardRefs.current[i + 1].style.top = `${nextCard.y}px`;
          }
        }
      };
  
      handleOverlapAdjustments();
    }, [activeRecommendationData, sortedRecommendationData]);
  
    return (
      <div className="suggestion-list">
        {sortedRecommendationData &&
          sortedRecommendationData.map((recommendation, index) => {
            if (String(recommendation.doc_id) !== String(id)) {
              return null;
            }
            const recommendationElement = document.querySelector(
              `span[data-location="recommendation_${recommendation.id}"]`
            );
  
            if (recommendationElement) {
              const currentIndex = recommendationElement.innerHTML.trim();
              const expectedIndex = (index + 1).toString();
  
              if (currentIndex !== expectedIndex) {
                recommendationElement.innerHTML = expectedIndex;
              }
            }
            const isActive =
              activeRecommendationData &&
              activeRecommendationData.id === recommendation.id;
  
            return (
              <div
                key={recommendation.id}
                ref={(el) => (cardRefs.current[index] = el)}
                className={`suggestion-card ${isActive ? "active" : ""}`}
                style={{
                  position: "absolute",
                  left: "0px",
                  top: `${recommendation.y}px`, 
                  transition: "top 0.2s ease",
                }}
                data-index={index + 1}
              >
                <SuggestionCardComponent
                  num={index + 1}
                  isVisible={
                    String(recommendation.y) === "-9999" ||
                    String(recommendation.y) === "hide"
                  }
                  recommendationData={recommendation}
                  isRecommendationAccepted={acceptedArray.current.includes(
                    recommendation.id
                  )}
                  isRecommendationRejected={rejectedArray.current.includes(
                    recommendation.id
                  )}
                  isActive={isActive}
                  onTapAccept={() => {
                    return addText(recommendation.id);
                  }}
                  updateAcceptStatus={() => {
                    acceptedArray.current = [
                      ...acceptedArray.current,
                      recommendation.id,
                    ];
                  }}
                  onTapReject={() => {
                    const result = rejectRecommendation(recommendation.id);
                    if (result) {
                      rejectedArray.current = [
                        ...rejectedArray.current,
                        recommendation.id,
                      ];
                    }
                  }}
                  onCoverTap={() => {
                    handleNavigation(recommendation);
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  };

  export default SuggestionList;